import { Component, OnInit } from '@angular/core';
import { RouterModule, Router, ActivatedRoute } from '@angular/router';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { ToastrService } from 'ngx-toastr';
import { ApiServiceService } from '../../services/api-service.service';
import { TranslateService } from '@ngx-translate/core';
import Typewriter from 't-writer.js';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

	logtoken = localStorage.getItem('LoginToken');
	social_link_data: any[] = [];
  lang;
  typerString;
  constructor(private router: Router, public api_service: ApiServiceService,
    private spinnerService: Ng4LoadingSpinnerService,
    public toastr: ToastrService, private translateService: TranslateService) { }

  ngOnInit() {
	  if(this.logtoken == '' || this.logtoken == null) {
			this.router.navigateByUrl('/')
				.then(() => {
					//localStorage.clear();
				//window.location.reload();
			});
		}
		this.getDashboardData();

    this.lang = localStorage.getItem("lang") || "en";
    if(this.lang == "en"){
      this.typerString = 'one chore at a time.';
    }else{
      this.typerString = 'عمل روتيني واحد في كل مرة';
    }
    const target = document.querySelector('.typewriter')
    const writer = new Typewriter(target, {
      loop: true,
      typeColor: '#00B2B2',
      cursorColor: '#00B2B2',
      typeSpeed: 300,
      deleteSpeed: 200,
      animateCursor: false,
    })
    
    writer
      .type(this.typerString)
      .rest(1000)
      .start()
  }


  getDashboardData() {
    this.spinnerService.show();
    this.api_service.HttpGetReq(`website/getDashboard`, true).then((response: any) => {
      this.spinnerService.hide();
      console.log("dashboard data => ", response);
      
      if(response.success){
        this.social_link_data = response.social_links;
      }
    },
      (error) => {
        this.spinnerService.hide();
      }
    );
  }

}
