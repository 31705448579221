import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { RouterModule, Router } from "@angular/router";
import { Ng4LoadingSpinnerService } from "ng4-loading-spinner";
import { ToastrService } from "ngx-toastr";
import { ApiServiceService } from "../../services/api-service.service";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-change-language",
  templateUrl: "./change-language.component.html",
  styleUrls: ["./change-language.component.css"],
})
export class ChangeLanguageComponent implements OnInit {
  logtoken = localStorage.getItem("LoginToken");
  user_type = Number(localStorage.getItem("user_type"));
  lang;
  message: any = "";
  newNotification = false;
  srNewNotification = false;
  userType;

  constructor(
    public formbuilder: FormBuilder,
    private router: Router,
    public api_service: ApiServiceService,
    private spinnerService: Ng4LoadingSpinnerService,
    public toastr: ToastrService,
    private translateService: TranslateService
  ) {
    // this.translateService.setDefaultLang('en');
    // this.translateService.use(localStorage.getItem('lang') || 'en');
  }

  ngOnInit() {
    if (this.logtoken == "" || this.logtoken == null) {
      this.router.navigateByUrl("/").then(() => {
        localStorage.clear();
        window.location.reload();
      });
    } else {
      this.lang = localStorage.getItem("lang") || "en";
      this.getUserProfile();
    }
  }

  getUserProfile() {
    this.spinnerService.show();
    this.api_service
      .HttpGetReq(`website/userProfileDetails?token=${this.logtoken}`, true)
      .then(
        (response: any) => {
          this.spinnerService.hide();
          if (response.type == "tokenexpire") {
            this.router.navigateByUrl("/").then(() => {
              localStorage.clear();
              window.location.reload();
            });
          }
          this.userType = response.data.user_type;
          if (response.data.user_type == 1) {
            this.getdefaultaddress(this.logtoken);
            this.srGetNewNotification();
          } else {
            this.getNewNotification();
          }
        },
        (error) => {
          this.spinnerService.hide();
        }
      );
  }
  getdefaultaddress(token: any) {
    this.spinnerService.show();
    this.api_service
      .HttpGetReq(`website/getdefaultaddress?token=${token}`, true)
      .then(
        (response: any) => {
          this.spinnerService.hide();
          //console.log('Default Address responce ====> ', response.data);
          if (!response.success) {
            this.router.navigateByUrl("/my-address-add").then(() => {
              window.location.reload();
            });
          }
        },
        (error) => {
          this.spinnerService.hide();
        }
      );
  }

  changeLang(lang: any) {
    this.lang = lang;
  }
  changeLangSubmit() {
    localStorage.setItem("lang", this.lang);
    // this.message = "Language change successfully.";
    // ($('#successMsg') as any).modal('show');
    if(this.lang == 'en')
    var body_obj = { language: 'eng' };
    if(this.lang == 'ar')
    var body_obj = { language: 'ar' };
		this.api_service.HttpPostReqHeader('website/changeLanguage', body_obj, true, this.logtoken).then((response:any)=>{
      console.log("backend language change response => ", response);
      
			if(response.success == true){
				this.spinnerService.hide();
			} else {
				this.spinnerService.hide();
			}
		});
    window.location.reload();
  }
  getNewNotification() {
    this.spinnerService.show();
    this.api_service
      .HttpGetReq(`website/getNewNotification?token=${this.logtoken}`, true)
      .then(
        (response: any) => {
          //console.log('get new notification response => ', response);
          if (response.success) {
            this.spinnerService.hide();
            this.newNotification = response.data;
          } else {
            this.spinnerService.hide();
            this.newNotification = response.data;
          }
        },
        (error) => {
          this.spinnerService.hide();
          this.newNotification = false;
        }
      );
  }

  srGetNewNotification() {
    this.spinnerService.show();
    this.api_service
      .HttpGetReq(`website/srGetNewNotification?token=${this.logtoken}`, true)
      .then(
        (response: any) => {
          //console.log('sr get new notification response => ', response);
          if (response.success) {
            this.spinnerService.hide();
            this.srNewNotification = response.data;
          } else {
            this.spinnerService.hide();
            this.srNewNotification = response.data;
          }
        },
        (error) => {
          this.spinnerService.hide();
          this.srNewNotification = false;
        }
      );
  }
}
