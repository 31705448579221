import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { RouterModule, Router } from '@angular/router';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { ToastrService } from 'ngx-toastr';
import { ApiServiceService } from '../../services/api-service.service';
import { MapsAPILoader } from '@agm/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-working-area-list',
  templateUrl: './working-area-list.component.html',
  styleUrls: ['./working-area-list.component.css']
})
export class WorkingAreaListComponent implements OnInit {
	showPopup = false;
	indexToDelete: number;
	logtoken = localStorage.getItem('LoginToken');
	user_type = Number(localStorage.getItem('user_type'));
	workingAreaData;
	lat: number = 45.464198;
	lng: number = 9.190545;
	latList:any = [];
	longList:any = [];
  newNotification = false;
  lang: any;

  constructor(public formbuilder: FormBuilder,
				private router: Router,public api_service: ApiServiceService,
				private spinnerService: Ng4LoadingSpinnerService,
				public toastr: ToastrService,private mapsAPILoader: MapsAPILoader,
				private translateService: TranslateService) { }
				

    ngOnInit() {
	    if(this.logtoken == '' || this.logtoken == null || this.user_type != 2) {
			this.router.navigateByUrl('/')
				.then(() => {
				localStorage.clear();
				window.location.reload();
			});
		}

		this.getWorkingAreaList();
    this.getNewNotification();
	this.lang = localStorage.getItem("lang") || "en";
    }

	getWorkingAreaList() {
		this.spinnerService.show();
		this.api_service.HttpGetReq(`website/workingAreaList?token=${this.logtoken}`, true)
        .then(
        (response: any) => {
          this.spinnerService.hide();
		  //console.log('c',response.data);
			if(response.type == 'tokenexpire') {
				this.router.navigateByUrl('/')
					.then(() => {
					localStorage.clear();
					window.location.reload();
				});
			}
			this.workingAreaData = response.data;
			for (var i = 0; i < this.workingAreaData.length; i++) {
				//console.log('abc',this.workingAreaData[i]);
				this.latList.push(parseFloat(this.workingAreaData[i].lattitude));
			}
			for (var i = 0; i < this.workingAreaData.length; i++) {
				this.longList.push(parseFloat(this.workingAreaData[i].longitude));
			}
        },
			(error) => {
			  this.spinnerService.hide();
			}
        );
	}

	addNewWorkArea() {
		this.router.navigateByUrl('/working-area-add').then(() =>{
			window.location.reload();
		})
	}

	getNewNotification() {
    this.spinnerService.show();
    this.api_service.HttpGetReq(`website/getNewNotification?token=${this.logtoken}`, true)
      .then(
        (response: any) => {
          //console.log('get new notification response => ', response);
          if (response.success) {
            this.spinnerService.hide();
            this.newNotification = response.data;
          } else {
            this.spinnerService.hide();
            this.newNotification = response.data;
          }
        },
        (error) => {
          this.spinnerService.hide();
          this.newNotification = false;
        });
  }

  readNotification(){
    this.api_service.HttpPostReqHeader('website/readNotification', {}, true, this.logtoken).then((response: any) => {
      //console.log("Notification read  => ",response);
      if (response.success == true) {
        this.spinnerService.hide();
        this.newNotification = false;
      } else {
        this.spinnerService.hide();
      }
    })
  }

  
//   deleteWorkingArea(index: number): void {
//     this.workingAreaData.splice(index, 1);
//     this.latList.splice(index, 1);
//     this.longList.splice(index, 1);
//   }

//   deleteWorkingArea(_id) {
// 	console.log(_id)
// 	this.spinnerService.show();

// 	if(_id) {
// 		const WorkingArea = {
// 			id: _id
// 		}
// 		this.api_service.HttpPostReqHeader('website/deleteDocument',WorkingArea,true,this.logtoken).then((response:any)=>{
// 			if(response.success == true){
// 				this.spinnerService.hide();
// 				this.getWorkingAreaList();
// 			} else{
// 				this.spinnerService.hide();
// 			}
// 		})
// 	}
// }
deleteWorkingArea(_id) {
    console.log(_id);
    this.spinnerService.show();

    if (_id) {
        const requestBody = {
            id: _id
        };

        this.api_service.HttpPostReqHeader('website/deleteWorkingArea', requestBody,true,this.logtoken)
            .then((response: any) => {
                if (response.success === true) {
                    this.spinnerService.hide();
                    this.getWorkingAreaList();
					alert(response.message)
                } else {
                    this.spinnerService.hide();
                    // Handle the case when deletion is not successful
                    console.error('Deletion failed:', response.error); // Log the error for debugging
                }
            })
            .catch((error) => {
                this.spinnerService.hide();
                // Handle other errors (e.g., network issues)
                console.error('Error:', error);
            });
    }
}
}
