import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { RouterModule, Router } from "@angular/router";
import { Ng4LoadingSpinnerService } from "ng4-loading-spinner";
import { ToastrService } from "ngx-toastr";
import { ApiServiceService } from "../../services/api-service.service";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.css']
})
export class FaqComponent implements OnInit {
  logtoken = localStorage.getItem("LoginToken");
  faqData: any;
  lang: any;
  cmsTitle: any;
  cmsContent: any;

  constructor(public formbuilder: FormBuilder,
    private router: Router,
    public api_service: ApiServiceService,
    private spinnerService: Ng4LoadingSpinnerService,
    public toastr: ToastrService,
    private translateService: TranslateService) { }

  ngOnInit() {
    this.getFaq();
    this.lang = localStorage.getItem("lang") || "en";
  }

  getFaq() {
    this.spinnerService.show();
    this.api_service
      .HttpGetReq(`website/getCMSPages?slug=faq`, true)
      .then(
        (response: any) => {
          this.spinnerService.hide();
          if (response.success) {
           
            if(this.lang == "en"){
              this.cmsTitle = response.data.cms_title;
              this.cmsContent = response.data.cms_content;
            }else{
              this.cmsTitle = response.data.ar_cms_title;
              this.cmsContent = response.data.ar_cms_content;
            }
            this.faqData = response.data;
          }
        },
        (error) => {
          this.spinnerService.hide();
        }
      );
  }

}
